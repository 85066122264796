<template>
  <div>
    <confirm-delete
      ref="modal"
      :msg="deleteMsg"
      :title="titleMsg"
      @on-submit="deleteUser"
    />

    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Admin Type</label>
            <v-select
              v-model="admin_list"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="adminFilter"
              class="w-100"
              :reduce="val => val.value"
              :clearable="false"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>First Name</label>
            <v-select
              v-model="first_name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filterList.first_name"
              label="filterList.first_name"
              class="w-100"
              :clearable="true"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Last Name</label>
            <v-select
              v-model="last_name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filterList.last_name"
              label="filterList.last_name"
              class="w-100"
              :clearable="true"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Email</label>
            <v-select
              v-model="email"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="filterList.email"
              label="filterList.email"
              class="w-100"
              :clearable="true"
            />
          </b-col>
        <!-- </b-row>
        <b-row> -->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Status</label>
            <v-select
              v-model="status_filter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusFilter"
              class="w-100"
              :reduce="val => val.value"
              :clearable="true"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.Add
              "
              class="d-flex align-items-center justify-content-end"
            >
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->
              <b-button variant="primary" @click="$router.push('add')">
                <span class="text-nowrap">Add Admin</span>
              </b-button>
              <export-csv :data="exportResults" name="Adminlist.csv">
              <template>
                <b-button class="ml-1" variant="secondary" :disabled="exportResults.length < 1">
                  <div v-if="exportResults.length < 1" class="spinner-border spinner-border-sm" />
                  <span v-else>Export</span>
                </b-button>
              </template>
            </export-csv>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="!contentLoading"
        ref="refUserListTable"
        class="position-relative overflow_table_cstm"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.firstName)"
                :variant="`light-${resolveUserRoleVariant(data.item.roleId)}`"
              />
            </template>
            {{ data.item.firstName }} {{ data.item.lastName }}
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.roleId)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.roleId)}`"
            />
            <span class="align-text-top text-capitalize">{{
              resolveUserRoleName(data.item.roleId)
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.isActive)}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusName(data.item.isActive) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="checkAccess.Edit || checkAccess.delete"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.Edit
              "
              :to="{ name: 'user-edit', params: { id: data.item.userId } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.delete
              "
              @click="openModal(data.item.userId, data.item.firstName)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="checkLoginRole() === RoleEnum.SuperAdmin"
              @click="signInAsAdmin(data.item.userId)"
            >
              <feather-icon icon="UserIcon" />
              <span class="align-middle ml-50">Login as admin</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <Loader v-if="contentLoading" />
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ totalUsers >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalUsers ? totalUsers : endIndex }} of
              {{ totalUsers }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="pageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import {
  resolveUserRoleIcon,
  resolveUserRoleVariant,
  resolveUserStatusVariant,
  resolveUserRoleName,
  resolveUserStatusName,
  perPageOptions,
  perPage,
  currentPage,
  deleteConfirmMsg,
  RoleEnum,
  statusFilter,
  resConditionCheck,
  adminFilter
} from "@/helpers/constant";
import accessRightCheck from "@/helpers/accessRightCheck";
import checkLoginRole from "@/helpers/checkLoginRole";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import errorResponseHandler from "@/services/errorHandler";
import { mapState } from "vuex";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import UserAdminService from "../../../../services/user-admin/userAdmin.service";
import ConfirmDelete from "../../../confirm-delete/ConfirmDelete.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ConfirmDelete,
    BCardHeader,
    BCardBody,
    Loader
  },
  data() {
    return {
      contentLoading: true,
      searchQuery: "",
      sortBy: "",
      totalUsers: 0,
      isSortDirDesc: false,
      avatarText,
      items: [],
      startIndex: 0,
      endIndex: 0,
      tableColumns: [
        { key: "user", sortable: true },
        { key: "email", sortable: true },
        { key: "role" },
        { key: "status" },
        { key: "actions" }
      ],
      currentPage,
      perPage,
      perPageOptions,
      resolveUserRoleIcon,
      resolveUserRoleVariant,
      resolveUserStatusVariant,
      resolveUserRoleName,
      resolveUserStatusName,
      resConditionCheck,
      statusFilter,
      deleteMsg: "",
      titleMsg: "",
      userId: "",
      toggleModal: false,
      filterList: [],
      first_name: "",
      last_name: "",
      email: "",
      signinAsAdmin: false,
      status_filter: null,
      checkAccess: {},
      checkLoginRole,
      RoleEnum,
      exportResults: [],
      adminFilter,
      admin_list: 1
    };
  },
  computed: {
    ...mapState(["auth"])
  },
  watch: {
    currentPage: {
      handler() {
        this.provider();
      }
    },
    perPage: {
      handler() {
        this.currentPage = 1;
        this.provider();
      }
    },
    sortBy: {
      handler(newValue) {
        this.provider();
        this.getExportData();
      }
    },
    isSortDirDesc: {
      handler() {
        this.provider();
        this.getExportData();
      }
    },
    first_name: {
      handler() {
        this.currentPage = 1;
        this.provider();
        this.getExportData();
      }
    },
    last_name: {
      handler() {
        this.currentPage = 1;
        this.provider();
        this.getExportData();
      }
    },
    email: {
      handler() {
        this.currentPage = 1;
        this.provider();
        this.getExportData();
      }
    },
    status_filter: {
      handler() {
        this.currentPage = 1;
        this.provider();
        this.getExportData();
      }
    },
    admin_list: {
      handler() {
        this.currentPage = 1;
        this.first_name = "";
        this.last_name = "";
        this.email = "";
        this.status_filter = null;
        this.provider();
        this.getExportData();
        this.getFilterList();
      }
    },
  },
  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    if (
      this.checkLoginRole() !== RoleEnum.SuperAdmin
      && !this.checkAccess.Edit && !this.checkAccess.delete
    ) {
      this.tableColumns = [
        { key: "user", sortable: true },
        { key: "email", sortable: true },
        { key: "role" },
        { key: "status" }
      ];
    }
    this.provider();
    this.getFilterList();
    this.getExportData();

  },

  methods: {
    pageChange() {
      window.scrollTo(0, 0);
    },

    provider() {
      this.contentLoading = true
      const payload = {
        no_of_result: this.perPage,
        page: this.currentPage,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy === "user" ? "firstName" : this.sortBy,
        admin_list: this.admin_list,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        status_filter: this.status_filter
      };
      UserAdminService.getAll(payload)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.user_details) {
            this.contentLoading = false;
            this.items = res.data.user_details;
            this.totalUsers = res.data.count;
            this.startIndex = this.currentPage * this.perPage - this.perPage;
            this.endIndex = this.startIndex + this.perPage;
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.contentLoading = false;
          if (error.response.status === 404) {
            this.items = [];
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },

    signInAsAdmin(id) {
      UserAdminService.signInAsAnotherUser({ user_id: id })
        .then(res => {
          if (resConditionCheck(res.status) && res.data.user_details) {
            localStorage.setItem("signinAsAdmin", true);
            this.$store.commit("auth/SET_TOKEN", res.data.user_details.token);
            this.$store.commit("auth/SET_USER", res.data.user_details);

            window.location.href = "/";

            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success"
              }
            });
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger"
            }
          });
        });
    },
    openModal(userId, userName) {
      this.titleMsg = deleteConfirmMsg;
      this.deleteMsg = `Are you sure you want to delete ${userName} admin ?`;
      this.userId = userId;
      this.$refs.modal.show();
    },
    deleteUser(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.modal.loading = true;
      if (this.userId) {
        UserAdminService.deleteAdmin(this.userId)
          .then(res => {
            if (resConditionCheck(res.status)) {
              this.$refs.modal.loading = false;
              this.$refs.modal.toggleModal = false;
              this.items = this.items.filter(
                item => item.userId !== this.userId
              );
              if (this.items.length === 0 && this.currentPage !== 1) {
                this.currentPage -= 1;
                this.provider();
              } else {
                this.provider();
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.modal.loading = false;
            this.$refs.modal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },
    // Get Filter Listing
    getFilterList() {
      const payload = this.admin_list === 1 ? RoleEnum.Admin : RoleEnum.SupportAgent;
      UserAdminService.getAdminFilter(payload)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.filterList = res.data.data;
          }
        })
        .catch(error => {
          errorResponseHandler(error);
        });
    },
    getExportData() {
      const payload = {
        no_of_result: 0,
        page: 1,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy === "user" ? "firstName" : this.sortBy,
        admin_list: this.admin_list,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        status_filter: this.status_filter
      };
      UserAdminService.getAll(payload)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.user_details) {
            this.exportResults = res.data.user_details.map(e => {
              return {
                Name: `${e.firstName} ${e.lastName}`,
                Email: e.email,
                Role: this.resolveUserRoleName(e.roleId),
                // Status: this.resolveUserStatusName(e.isActive)
              }
            })
          }
        })
        .catch(error => {
          this.exportResults = this.items
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.dropdown-menu {
  right: 0;
  left: auto;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
